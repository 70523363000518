<template>
<div class='card'>
    <h5 class='font-bold'>Groups</h5>
</div>
</template>

<script>
export default {
    name: 'Groups',
};
</script>

<style scoped>

</style>